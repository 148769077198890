<template>
  <div id="openAccount">
    <div class="content_box">
      <div class="inner">
        <div class="content_title">
          <h2>{{ $t('openDemoAdditAcc.header') }}</h2>
          <p></p>
        </div>
        <div class="account_shadow_box" v-if="!success">
          <p class="account_title">{{ $t('register.accountConfig.pageTitle') }}</p>
          <el-form :model="form" :rules="rules" ref="ruleForm">
            <el-row :gutter="60">
              <el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseAccType') }}</label>
                </div>
                <el-form-item prop="accountType">
                  <SelectForm v-model="form.accountType" name="accountType">
                    <el-option
                      v-for="(item, index) in $config.openLiveAccount.mt4DemoChooseType(regulator)"
                      :key="index"
                      :value="item.value"
                      :label="item.label"
                      :data-testid="item.value"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
              </el-col>

              <el-col el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseCurrency') }}</label>
                </div>
                <el-form-item prop="currency">
                  <SelectForm v-model="form.currency" name="currency">
                    <el-option
                      v-for="item in $config.openLiveAccount.chooseCurrency(regulator, $store.state.common.countryCode)"
                      :key="item.currency"
                      :value="item.currency"
                      :label="item.value"
                      :data-testid="item.currency"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
              </el-col>

              <el-col el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseLeverage') }}</label>
                </div>
                <el-form-item prop="leverage">
                  <SelectForm v-model="form.leverage" name="leverage">
                    <el-option
                      v-for="item in $config.openLiveAccount.chooseLeverage(regulator)"
                      :key="item"
                      :value="item"
                      :data-testid="item"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
              </el-col>

              <el-col el-col :xs="24" :sm="12" :md="12">
                <div class="labelTitle">
                  <span class="required_icon">*</span>
                  <label for="">{{ $t('openAdditAcc.chooseBalance') }}</label>
                </div>
                <el-form-item prop="balance">
                  <SelectForm v-model="form.balance" name="balance">
                    <el-option
                      v-for="item in $config.openLiveAccount.chooseBalance(regulator)"
                      :key="item.value"
                      :value="item.value"
                      :data-testid="item"
                    ></el-option>
                  </SelectForm>
                </el-form-item>
              </el-col>
            </el-row>

            <div class="btn_item">
              <el-button class="purple_button" @click="submitClick" data-testid="submit">{{
                $t('common.button.submit')
              }}</el-button>
            </div>
          </el-form>
        </div>
        <Result backUrl="homeDemo" v-else>
          <div v-html="$t('openDemoAdditAcc.succ')"></div>
        </Result>
      </div>
    </div>
  </div>
</template>

<script>
import Result from '@/components/Result';
import { apiCreateDemoAccount } from '@/resource';

export default {
  name: 'openDemoAccount',
  components: { Result },
  data() {
    return {
      form: {
        tradingPlatform: `mt${this.$route.query.p}`,
        accountType: 'standardSTP',
        currency: 'USD',
        leverage: 100,
        balance: 100000
      },
      rules: {
        accountType: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.accTypeErr')
          }
        ],
        currency: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.currError')
          }
        ],
        leverage: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.leverageErr')
          }
        ],
        balance: [
          {
            required: true,
            trigger: 'blur',
            message: this.$t('openAdditAcc.balanceErr')
          }
        ]
      },
      success: false
    };
  },
  methods: {
    createDemoAccount() {
      return apiCreateDemoAccount({ ...this.form });
    },
    submitClick() {
      return this.$refs['ruleForm'].validate(valid => {
        if (valid) {
          this.createDemoAccount().then(resp => {
            if (resp.data.code === 0) this.success = true;
          });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/openAccount.scss';
</style>
