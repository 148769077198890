<template>
  <div class="success">
    <div class="success_content">
      <img v-if="fail" src="@/assets/images/dialog_false.png" alt />
      <img v-else-if="info" src="@/assets/images/dialog_info.png" alt />
      <img v-else src="@/assets/images/dialog_true.png" alt />

      <div class="main">
        <slot></slot>
      </div>

      <router-link :to="{ name: backUrl ? backUrl : 'home' }" class="el-button purple_button" data-testid="bkToHm">
        {{ $t('common.button.bkToHm') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'result',
  props: {
    fail: Boolean,
    info: Boolean,
    backUrl: String
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/components/success.scss';
</style>
